import React, { Component } from 'react'
import { withRouter } from 'utils/customNavigate'
import PropTypes from 'prop-types'
import User from './User'
import getCurrencyDetails from 'utils/get-currency-details'
import * as urls from 'constants/paths'
import axios from 'axios'
import avatarPlaceholder from 'static/images/avatar-placeholder.png'
import {
  ProfessionalInvestorTypes,
  USInvestorAcreditationStatuses,
} from 'constants/enums'

export const UserContext = React.createContext()

class UserProviderClass extends Component {
  state = {
    user: undefined,
    avatar: avatarPlaceholder,
    isFetchingUser: true,
    hasStartedSetUser: false,
  }

  componentDidMount() {
    const { exchangeRates, project, authUser } = this.props
    const { hasStartedSetUser } = this.state

    if (authUser && exchangeRates && project && !hasStartedSetUser) {
      this.setState({ hasStartedSetUser: true })
      this.setUser({ ...authUser })

      // This ensures the if condition
      // will only run once
    }
  }

  componentDidUpdate() {
    const { exchangeRates, project, authUser } = this.props
    const { hasStartedSetUser } = this.state

    if (authUser && exchangeRates && project && !hasStartedSetUser) {
      this.setState({ hasStartedSetUser: true })
      this.setUser({ ...authUser })

      // This ensures the if condition
      // will only run once
    }
  }

  setUser = async (user) => {
    const { project, exchangeRates } = this.props
    if (
      (await this.checkPrivateInvestor(user, project)) &&
      (user.wallets || []).some((wallet) =>
        wallet.assetIds?.includes(project.primaryAssetId),
      )
    ) {
      let tokenBalances = []
      try {
        tokenBalances = await User.getTokenBalance()
      } catch (error) {
        console.log('🚀 ~ error in tokenBalances', error)
      }
      const findTokenBalance = (assetId, address) => {
        const entry = tokenBalances.find(
          ({ asset, account }) => asset === assetId && account === address,
        )
        return entry ? entry.balance : 0
      }

      user.wallets = user.wallets
        .filter(
          (wallet) =>
            wallet.type === project.token_issuance_type && !wallet.assetIds,
        )
        .map((wallet) => {
          const balance = findTokenBalance(
            project.primaryAssetId,
            wallet.address,
          )
          const currencyBalanceBMN1 = findTokenBalance(
            project.tertiaryAssetId,
            wallet.address,
          )
          const currencyBalanceBMN1inBTC =
            currencyBalanceBMN1 * exchangeRates.bmn1inBTC
          return {
            ...wallet,
            balance,
            currencyBalanceBMN1,
            currencyBalanceBMN1inBTC,
            tokenBalanceinEURorUSD: balance * project.tokenPrice,
            isWhitelisted: (user.wallets || []).some((wallet) =>
              wallet.assetIds?.includes(project.primaryAssetId),
            ),
          }
        })

      const sum = (xs) => xs.reduce((s, x) => s + x, 0)

      user.totalTokenBalance = sum(user.wallets.map((wallet) => wallet.balance))
      user.totalTokenBalanceBMN1 = sum(
        user.wallets.map((wallet) => wallet.tokenBalanceBMN1),
      )
      user.totalTokenBalanceinEURorUSD =
        user.totalTokenBalance * project.tokenPrice

      //TODO: update it after adding statuses
      user.isProfessionalInvestor =
        user.professional_investor_status ===
        ProfessionalInvestorTypes.PROFESSIONAL

      user.isUSAccredited =
        user.us_accredited_status ===
        USInvestorAcreditationStatuses.US_ACCREDITED

      const avatar = await this.getProfilePicture(user._id)

      this.setState({
        user,
        // userWallet,
        avatar,
        isFetchingUser: false,
      })
    } else {
      this.props.navigate(urls.OOPS, {
        state: {
          message: 'You have no whitelisted wallets for this Project',
        },
      })
      this.setState({
        isFetchingUser: false,
      })
    }
  }

  getProfilePicture = (userId) => {
    return new Promise(async (resolve, reject) => {
      const avatarUrl = `${process.env.REACT_APP_PHOTO_API_URL}/media/picture/view/${userId}`

      axios
        .head(avatarUrl)
        .then((res) => {
          if (res.status === 200) {
            resolve(avatarUrl)
          } else resolve(avatarPlaceholder)
        })
        .catch((err) => resolve(avatarPlaceholder))
    })
  }

  checkPrivateInvestor = async (user, project) => {
    try {
      const isPrivateSale = await User.allowedForPrivateSale({
        userId: user._id,
        projectId: project._id,
      })
      user.isPrivateSale = isPrivateSale.isAllowed
      if (
        (window.location.href.includes('privateSale') ||
          project.only_private_investors) &&
        !user.testingUser &&
        !user.isPrivateSale
      ) {
        this.props.navigate(urls.OOPS, {
          state: {
            message:
              'You are not registered as a private Investor for this Project. Please contact support@stokr.io',
          },
        })
        return false
      }

      return true
    } catch (error) {
      console.error(error)

      this.props.navigate(urls.OOPS, {
        state: {
          message: 'Can not reach the server. Please contact support@stokr.io',
        },
      })

      return false
    }
  }

  setSelectedCurrency = async (selectedCurrency) => {
    const { exchangeRates } = this.props
    let { project } = this.props
    let { user } = this.state

    try {
      const currencyDetails = getCurrencyDetails(
        selectedCurrency,
        project.acceptedCurrencies,
      )

      switch (selectedCurrency) {
        case 'tether':
          this.setState({
            user: {
              ...user,
              agreementRequired: false,
              currencySymbol: currencyDetails.symbol,
              currencyName: currencyDetails.displayName,
              currencyInternalReference: currencyDetails.internalReference,
              secondaryCurrency: '',
              secondaryCurrencyinEURorUSD: '',
              isIBAN: false,
              isBFXPay: true,
              isBTCPay: false,
              isBMN1: false,
              selectedCurrencyinEURorUSD: exchangeRates.pricesinEURorUSD.USD,
              currencyDecimals: 2,
            },
          })

          break

        case 'euro':
          this.setState({
            user: {
              ...user,
              agreementRequired: false,
              currencySymbol: currencyDetails.symbol,
              currencyName: currencyDetails.displayName,
              currencyInternalReference: currencyDetails.internalReference,
              secondaryCurrency: '',
              secondaryCurrencyinEURorUSD: '',
              isIBAN: true,
              isBFXPay: false,
              isBTCPay: false,
              isBMN1: false,
              selectedCurrencyinEURorUSD: exchangeRates.pricesinEURorUSD.EUR,
              currencyDecimals: 2,
            },
          })
          break
        case 'usd':
          this.setState({
            user: {
              ...user,
              agreementRequired: false,
              currencySymbol: currencyDetails.symbol,
              currencyName: currencyDetails.displayName,
              currencyInternalReference: currencyDetails.internalReference,
              secondaryCurrency: '',
              secondaryCurrencyinEURorUSD: '',
              isIBAN: true,
              isBFXPay: false,
              isBTCPay: false,
              isBMN1: false,
              selectedCurrencyinEURorUSD: exchangeRates.pricesinEURorUSD.USD,
              currencyDecimals: 2,
            },
          })
          break
        case 'chf':
          this.setState({
            user: {
              ...user,
              agreementRequired: false,
              currencySymbol: currencyDetails.symbol,
              currencyName: currencyDetails.displayName,
              currencyInternalReference: currencyDetails.internalReference,
              secondaryCurrency: '',
              secondaryCurrencyinEURorUSD: '',
              isIBAN: true,
              isBFXPay: false,
              isBTCPay: false,
              isBMN1: false,
              selectedCurrencyinEURorUSD: exchangeRates.pricesinEURorUSD['CHF'],
              currencyDecimals: 2,
            },
          })
          break
        case 'bitcoin':
        case 'lbtc':
          this.setState({
            user: {
              ...user,
              agreementRequired: false,
              currencySymbol: currencyDetails.symbol,
              currencyName: currencyDetails.displayName,
              currencyInternalReference: currencyDetails.internalReference,
              secondaryCurrency: '',
              secondaryCurrencyinEURorUSD: '',
              isIBAN: false,
              isBFXPay: false,
              isBTCPay: true,
              isBMN1: false,
              selectedCurrencyinEURorUSD: exchangeRates.pricesinEURorUSD['BTC'],
              currencyDecimals: 8,
            },
          })

          break
        case 'lusdt-bfx':
          this.setState({
            user: {
              ...user,
              agreementRequired: false,
              currencySymbol: currencyDetails.symbol,
              currencyName: currencyDetails.displayName,
              currencyInternalReference: currencyDetails.internalReference,
              secondaryCurrency: '',
              secondaryCurrencyinEURorUSD: '',
              isIBAN: false,
              isBFXPay: true,
              isBTCPay: false,
              isBMN1: false,
              selectedCurrencyinEURorUSD: exchangeRates.pricesinEURorUSD.USD,
              currencyDecimals: 2,
            },
          })

          break
        case 'btc-bfx':
        case 'lbtc-bfx':
          this.setState({
            user: {
              ...user,
              agreementRequired: false,
              currencySymbol: currencyDetails.symbol,
              currencyName: currencyDetails.displayName,
              currencyInternalReference: currencyDetails.internalReference,
              secondaryCurrency:
                selectedCurrency === 'btc-bfx' ? 'BTC' : 'LBTC',
              secondaryCurrencyinEURorUSD:
                exchangeRates.pricesinEURorUSD['BTC'],
              isIBAN: false,
              isBFXPay: true,
              isBTCPay: false,
              isBMN1: false,
              selectedCurrencyinEURorUSD:
                exchangeRates.pricesinEURorUSD[project.tokenCurrency],
              currencyDecimals: 8,
            },
          })

          break

        case 'bmn1':
          this.setState({
            user: {
              ...user,
              agreementRequired: false,
              currencySymbol: currencyDetails.symbol,
              currencyName: currencyDetails.displayName,
              currencyInternalReference: currencyDetails.internalReference,
              secondaryCurrency: selectedCurrency === 'BTC',
              secondaryCurrencyinEURorUSD:
                exchangeRates.pricesinEURorUSD['BTC'],
              isIBAN: false,
              isBFXPay: false,
              isBTCPay: false,
              isBMN1: true,
              selectedCurrencyinEURorUSD:
                exchangeRates.pricesinEURorUSD['BTC'] * exchangeRates.bmn1inBTC,
              currencyDecimals: 2,
            },
          })

          break
        default:
          throw new Error(`Unknown currency: ${selectedCurrency}`)
      }
    } catch (error) {
      console.error(error)
      this.props.navigate(urls.OOPS, {
        state: {
          message:
            'Unknown currency selected, you should never see this message. Please contact support@stokr.io',
        },
      })
      throw error
    }
  }

  setAfterValues = (afterValues) => {
    let { user } = this.state

    this.setState({
      user: {
        ...user,
        afterValues,
      },
    })
  }

  setSelectedAddress = (address) => {
    console.log('address: ', address)
    let { user } = this.state

    this.setState({
      user: {
        ...user,
        selectedAddress: address,
      },
    })
  }

  updateUser = async (data) => {
    try {
      const { user } = await User.updateUser(data)

      this.setState({
        user,
      })
    } catch (error) {
      throw error
    }
  }

  render() {
    const { children } = this.props

    return (
      <UserContext.Provider
        value={{
          ...this.state,
          logoutUser: this.logoutUser,
          updateUser: this.updateUser,
          setSelectedCurrency: this.setSelectedCurrency,
          setSelectedAddress: this.setSelectedAddress,
          setAfterValues: this.setAfterValues,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

UserProviderClass.propTypes = {
  children: PropTypes.node.isRequired,
}

export const UserProvider = withRouter(UserProviderClass)
export const UserConsumer = UserContext.Consumer
